<template>
	<modal-wrapper @close="close">
		<div class="dialog">
			<dlg-header title="Consultant List" @close="close()" />
			<div style="background: var(--primary-bg); padding: 16px; border-top: 1px solid var(--secondary-border)">
				<template v-if="!consultants.length"></template>
				<template v-else>
					<h4 style="padding-bottom: 2em;">
						Configure which consultants you would like to appear in the list when requesting consultation.
					</h4>
					<consultant-cards
						v-if="consultants.length"
						#default="{ consultant }"
						:consultants="consultants"
						:is-selectable="false"
					>
						<div class="actions" :class="{ 'consultant-hidden': consultant.isHidden }" style="padding: 16px;">
							<ast-button v-if="!consultant.isHidden" type="danger" @click.native="toggleConsultant(consultant)">
								Hide from list
							</ast-button>
							<p v-else style="font-size: 0.85em; font-weight: 600;">
								This consultant is hidden.
								<a @click.prevent="toggleConsultant(consultant)">Restore</a>
							</p>
						</div>
					</consultant-cards>
				</template>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import AstButton from '@components/Button'
import ConsultantCards from '@components/ConsultantCards.vue'
import { mapState } from 'vuex'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import DlgHeader from '@components/DlgHeader.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import consultations from '@services/consultationService'

const ConsultantListDlg = {
	name: 'ConsultantList',
	components: {
		AstButton,
		ConsultantCards,
		DlgHeader,
		ModalWrapper,
	},
	data() {
		return {
			autoComplete: {
				key: '',
				replacement: '',
			},
			isEntrySaving: false,
		}
	},
	computed: {
		...mapState({
			consultants: state => state.static.consultants,
		}),
	},
	methods: {
		async toggleConsultant(consultant) {
			const isLastVisibleConsultant = !consultant.isHidden && this.consultants.filter(c => !c.isHidden).length === 1
			const warning =
				'Hiding this consultant will leave you with no consultants for requesting ' +
				'consultation.  Are you sure you want to do this?'
			if (isLastVisibleConsultant && !(await showConfirm(warning))) return
			consultant.isHidden = !consultant.isHidden
			this.$store.commit('SET_CONSULTANTS', this.consultants.slice())
			await consultations.updateConsultantVisibility({
				consultantId: consultant.id,
				isHidden: consultant.isHidden,
			})
		},
		close() {
			dlg.close(this)
		},
	},
}

export default ConsultantListDlg

export function openConsultantListDlg() {
	return dlg.open(ConsultantListDlg)
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.dialog {
	display: flex;
	position: relative;
	flex-direction: column;
	padding: 0;
	height: 100vh;
	width: 100vw;
	max-width: 100vw;
	@media (min-width: $mqSmall) {
		width: 1100px;
		max-width: 90vw;
		height: auto;
		max-height: 90vh;
	}
}
.consultant-hidden {
	background: var(--banner-info-bg);
	a {
		text-decoration: underline;
		padding: 16px;
	}
}
</style>
