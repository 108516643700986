<template>
	<modal-wrapper :close-on-overlay-click="false" @close="close">
		<div class="dialog user-profile">
			<dlg-header title="User Profile" @close="close" />
			<ast-user-profile-form
				v-if="!isLoading"
				:current-user="currentUser"
				:is-submitting="isSubmitting"
				:is-new-user="isNewUser"
				:is-setting-defaults="isSettingDefaults"
				class="profile-form"
				@cancel="close()"
				@submit="submitChanges"
			/>
			<p v-else class="loading">
				<svg-icon icon="spinner" pulse class="is-muted" />
			</p>
		</div>
	</modal-wrapper>
</template>

<script>
import AstUserProfileForm from '@components/UserProfileForm'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import DlgHeader from '@components/DlgHeader.vue'
import { userData } from '@services/userData'

const UserProfileDlg = {
	name: 'UserProfile',
	components: {
		AstUserProfileForm,
		ModalWrapper,
		DlgHeader,
	},
	props: {
		userId: {
			type: String,
			required: false,
		},
		isNewUser: {
			type: Boolean,
			default: false,
		},
		isSettingDefaults: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			currentUser: {},
			isLoading: true,
			isSubmitting: false,
		}
	},
	created() {
		this.getCurrentUser()
	},
	methods: {
		async getCurrentUser() {
			this.isLoading = true
			try {
				if (this.isNewUser || this.isSettingDefaults) {
					this.currentUser = await userData.getUserDefaults()
				} else {
					this.currentUser = await userData.getUserDetails(this.userId)
				}
			} finally {
				this.isLoading = false
			}
		},
		async submitChanges(form) {
			this.isSubmitting = true
			try {
				let promises = []
				if (this.isSettingDefaults) {
					await userData.updateUserDefaults(form)
				} else if (this.isNewUser) {
					await userData.createUser(form)
				} else {
					await userData.updateUser(form)
				}
				if (form.signatureFile) {
					promises.push(userData.updateSignature(form.userId, form.signatureFile))
				}
				if (form.logoFile) {
					promises.push(userData.updateLogo(form.userId, form.logoFile))
				}
				await Promise.all(promises)

				this.close()
				let message = 'User profile updated!'
				if (this.isNewUser) message = 'User created!'
				if (this.isSettingDefaults) message = 'User defaults updated!'
				this.$store.dispatch('addNotification', {
					message,
					notificationType: 'success',
				})
				this.$store.dispatch('refreshTokenAndBroadcast', form)
			} finally {
				this.isSubmitting = false
			}
		},
		close() {
			dlg.close(this)
		},
	},
}

export default UserProfileDlg

export function openUserProfileDlg(userId, isNewUser, isSettingDefaults) {
	return dlg.open(UserProfileDlg, { userId, isNewUser, isSettingDefaults })
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.loading {
	position: absolute;
	top: 20px;
	width: 100%;
	text-align: center;
	font-size: 3em;
}
.user-profile {
	display: flex;
	position: relative;
	flex-direction: column;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	padding: 0;
	@media (min-width: $mqSmall) {
		width: 800px;
		height: 90vh;
	}

	.profile-form {
		min-height: 0;
		border-top: 1px solid var(--primary-border);
	}
}
</style>
