


































































































import { dicomSend } from '@services/dicomSend'
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import { openDicomPeersDlg } from '@dialogs/DicomPeersDlg.vue'
import { showLog } from './MessageDlg.vue'
import AstCheckbox from '@components/Checkbox.vue'
import { stringToDate } from '../utils/dateUtils'

const DicomSendInfo = {
	name: 'DicomSendInfo',
	components: {
		ModalWrapper,
		DlgHeader,
		AstCheckbox,
	},
	data() {
		return {
			todayOnly: true,
			state: dicomSend,
		}
	},
	computed: {
		items() {
			if (this.todayOnly) {
				const startOfToday = new Date()
				startOfToday.setHours(0, 0, 0, 0)
				return this.state.studyItems.filter(i => stringToDate(i.dateQueued) >= startOfToday)
			} else {
				return this.state.studyItems
			}
		},
	},
	created() {
		dicomSend.enabledUpdates(true)
	},
	methods: {
		devices: openDicomPeersDlg,
		viewLogs(item) {
			showLog(item.logs)
		},
		cancelSend(queueItemId) {
			dicomSend.cancelItem(queueItemId)
		},
		close() {
			dlg.close(this)
			dicomSend.forceUpdates = false
		},
	},
}

export default DicomSendInfo

export function openDicomSendInfo() {
	return dlg.open(DicomSendInfo, {})
}
