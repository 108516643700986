<template>
	<ast-toolbar-dropdown
		v-if="isAuthenticated"
		class="help-btn label-right"
		icon="account-circle"
		:label="label"
		:items="items"
		data-test="UserMenu"
	/>
</template>

<script>
import AstToolbarDropdown from '@components/ToolbarDropdown.vue'
import { openConsultantListDlg } from '@dialogs/ConsultantList.vue'
import { openUserProfileDlg } from '@dialogs/UserProfile.vue'
import { openOnBehalfOfDlg } from '@dialogs/OnBehalfOf.vue'
import { openDicomServiceDlg } from '@dialogs/DicomServiceDlg.vue'
import { openDicomSendInfo } from '@dialogs/DicomSendInfo.vue'
import { openSettings } from '@dialogs/Settings.vue'
import { openLaunchOrDownloadDialog } from '@dialogs/LaunchOrDownloadDesktop.vue'
import { openTimeMessage } from '@dialogs/OneTimeMessage.dialog.vue'

import store from '@store'
import { omniDesktop } from '@/electron/omniDesktop'
import { mapGetters, mapState } from 'vuex'
import { dicomServiceData } from '@services/dicomServiceData'

export default {
	name: 'UserMenu',
	components: { AstToolbarDropdown },
	data() {
		return {
			dicomServiceData,
			open: false,
			items: [
				{
					label: '<fullname>',
					noAction: true,
					canShow: () => !this.mq.small,
				},
				{
					label: 'Edit Profile',
					iconName: 'edit',
					command: () => openUserProfileDlg(),
					canShow: () => this.canEditProfile,
				},
				{
					label: 'DICOM Service',
					iconName: 'cloud-upload',
					route: true,
					command: () => openDicomServiceDlg(),
				},
				{
					label: 'DICOM Send Queue',
					iconName: 'paper-plane',
					route: true,
					canShow: () => !this.claims.isCommunityUser && !this.claims.isRepositoryUser,
					command: () => openDicomSendInfo(),
				},
				{
					label: 'Consultant List',
					iconName: 'user-md',
					route: true,
					canShow: () => this.claims.isCommunityUser,
					command: () => openConsultantListDlg(),
				},
				{
					label: 'On Behalf Of List',
					iconName: 'account-box-multiple',
					canShow: () => this.claims.isCommunityUser,
					command: () => openOnBehalfOfDlg(),
				},
				{
					label: 'Switch to Dark Mode',
					iconName: 'moon',
					canShow: () => !this.themeIsDark,
					command: () => store.dispatch('setThemeAndBroadcast', { mode: 'dark' }),
				},
				{
					label: 'Switch to Light Mode',
					iconName: 'sun',
					canShow: () => this.themeIsDark,
					command: () => store.dispatch('setThemeAndBroadcast', { mode: 'light' }),
				},
				{
					label: 'Settings',
					iconName: 'settings',
					canShow: () => !this.claims.isCommunityUser,
					command: () => openSettings(),
				},
				{
					label: 'Omni Desktop',
					iconName: 'external-link',
					canShow: () => this.canShowOmniDesktopLink,
					command: async () => {
						openLaunchOrDownloadDialog()
					},
				},
				{
					label: 'Log Out',
					iconName: 'sign-out',
					command: () => store.dispatch('logOut'),
				},
			],
		}
	},
	computed: {
		...mapGetters(['themeIsDark', 'isAuthenticated']),
		...mapState({
			claims: state => state.auth.claims,
			token: state => state.auth.token,
			desktopUpdateInfo: state => state.ui.desktopUpdateInfo,
		}),
		label() {
			let result = ''
			if (this.mq.small) {
				result = this.$store.getters.fullname
			}
			if (result.length > 20) {
				result = this.$store.getters.username
			}
			return result
		},
		canEditProfile() {
			// this will eventually include more logic, but for now, all repository users lose editProfile privileges
			return !this.claims.isRepositoryUser
		},
		canShowOmniDesktopLink() {
			return (
				!omniDesktop.isConnected &&
				!this.isMobileOS &&
				!this.claims.isCommunityUser &&
				!this.claims.isRepositoryUser &&
				!!this.desktopUpdateInfo &&
				this.desktopUpdateInfo.updateVersion &&
				this.desktopUpdateInfo.installType === 1
			)
		},
	},
	watch: {
		claims: {
			handler() {
				this.items[0].label = this.$store.getters.fullname
			},
			deep: true,
		},
	},
	created() {
		// If necessary in the future, just uncomment this
		// this.showOneTimeMessageDlg()
		this.items[0].label = this.$store.getters.fullname
	},
	methods: {
		async messageViewed() {
			await this.$api.user.setUserSetting('viewedIssuesMessage', 'serverNotes', true)
		},
		async showOneTimeMessageDlg() {
			// if (this.isAuthenticated) {
			// 	// Example of data: {name:"viewedIssuesMessage", type: serverNotes, value: true}
			// 	const viewedIssuesMessage = (await this.$api.user.getUserSetting('viewedIssuesMessage', 'serverNotes')).data
			// 	if (!viewedIssuesMessage) {
			// 		openTimeMessage(
			// 			'Asteris Update',
			// 			'We are experiencing heavy network usage traffic that is causing connectivity issues with Omni, telemedicine, and archiving. You may also be experiencing 502 and 503 errors in Omni. We are working diligently to resolve these issues. We regret any inconvenience and look forward to addressing the issue as soon as possible.'
			// 		)
			// 		await this.messageViewed()
			// 	}
			// }
		},
	},
}
</script>

<style lang="scss">
#user-menu {
	display: inline-block;

	.mainButton {
		padding: 10px;
		background: green;
		cursor: pointer;
	}

	.list {
		position: absolute;
		z-index: 9;
		background: white;
		color: black;
	}

	.item {
		padding: 10px;
		&:hover {
			background: gray;
			cursor: pointer;
		}
	}
}
</style>
