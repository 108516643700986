<template>
	<section>
		<div class="consultant-cards" :class="{ 'is-muted': isLoading }">
			<div
				v-for="consultant in consultants"
				:key="consultant.id"
				class="consultant-card"
				:class="{ 'is-selectable': isSelectable }"
				@click="isSelectable && $emit('select', consultant)"
			>
				<div :class="consultant.isHidden ? 'is-muted' : ''">
					<h4 class="consultant-name">
						{{ consultant.name }}
						<svg-icon v-if="isSelectable" icon="chevron-right" class="is-muted" />
					</h4>
					<p class="consultant-type">
						{{ consultant.type | consultantType }}
					</p>
					<ul>
						<li v-if="consultant.organizationName" class="consultant-organization">
							{{ consultant.organizationName }}
						</li>
						<li v-if="consultant.address">
							{{ consultant.address }}
						</li>
						<li v-if="consultant.city || consultant.state">
							{{ consultant.city + (consultant.state ? ', ' : '') }}
							{{ consultant.state }}
							{{ consultant.postalCode }}
						</li>
						<li v-if="consultant.phone || consultant.email" class="contact">
							{{ consultant.phone }}
							<span v-if="consultant.phone && consultant.email">&middot;</span>
							{{ consultant.email }}
						</li>
						<li v-if="consultant.website">
							<a :href="consultant.website | url" target="_blank" @click.stop>
								{{ consultant.website }}
							</a>
						</li>
					</ul>
				</div>
				<slot :consultant="consultant"></slot>
			</div>
			<transition name="fade">
				<div v-if="consultants.length && isLoading" class="loading">
					<svg-icon icon="spinner" pulse />
				</div>
			</transition>
		</div>
	</section>
</template>

<script>
export default {
	name: 'ConsultantCards',
	filters: {
		consultantType(value) {
			if (value === 'Repository') return 'Sale Repository'
			if (value === 'ImageOnly') return 'Accepts Images Only'
			return 'Accepts Teleconsultation Requests'
		},
	},
	props: {
		consultants: {
			type: Array,
			required: true,
		},
		isSelectable: {
			type: Boolean,
			default: true,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';
.consultant-cards {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	width: 100%;
	height: 100%;
}
.consultant-card {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: var(--secondary-bg);
	border: 1.25px solid var(--secondary-border); // 1.25px due to weird rendering
	margin: 0 1.5em 1.5em 0;
	width: 300px;
	overflow: hidden;
	&.is-selectable {
		cursor: pointer;
		&:hover {
			border-color: var(--button-default-bg-hover);
			h4 {
				background: var(--button-default-bg-hover);
			}
		}
		&:not(:hover) {
			h4 {
				background: var(--button-default-bg);
			}
		}
	}
	h4 {
		padding: 1em;
		overflow: hidden;
		transition: background 0.1s ease;
		.icon {
			float: right;
			font-size: 1.25em;
		}
	}
	.consultant-type {
		padding: 0.5em 1.25em;
		font-size: 0.8em;
		font-weight: 400;
	}
	ul {
		list-style-type: none;
		padding: 1em;
	}
	li {
		font-size: 0.8em;
		padding: 0.1em 0;
	}
	.consultant-name {
		font-weight: bold;
		font-size: 1em;
	}
	.consultant-organization {
		text-transform: uppercase;
		letter-spacing: 0.05em;
	}
	.contact {
		padding-top: 0.5em;
	}
	a:hover,
	a:active {
		text-decoration: underline;
	}
}
@media (max-width: $mqSmall) {
	.consultant-card {
		width: 100%;
		margin: 0 0 2px 0;
	}
}
.loading {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	font-size: 4em;
}
</style>
