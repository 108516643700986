<template>
	<ast-toolbar-button
		:icon="_icon"
		:label="_label"
		:title="_title"
		:badge="_badge"
		:dropdown-location="dropdownLocation"
	>
		<template #dropdown="{ hide }">
			<ast-toolbar-menu :items="filteredItems" :capture-event="true" @select="e => onSelect(e, hide)">
				<template #item="{ item }">
					<div class="item-label" :class="{ disabled: item.disabled }" @contextmenu.prevent>
						<p>
							<svg-icon v-if="item.iconName" :icon="item.iconName" style="opacity: 0.7; padding-right: 8px;" />
							<span class="no-break">{{ item.title || item.label }}</span>
						</p>
						<span v-if="item.badge || item.binding" class="badge">
							{{ item.badge || item.binding }}
						</span>
						<svg-icon v-if="item.active" icon="check-circle" class="active-setting" />
					</div>
				</template>
			</ast-toolbar-menu>
		</template>
	</ast-toolbar-button>
</template>

<script>
import AstToolbarMenu from '@components/ToolbarMenu'
import AstToolbarButton from '@components/ToolbarButton'
export default {
	name: 'ToolbarDropdown',
	components: {
		AstToolbarButton,
		AstToolbarMenu,
	},
	props: {
		items: {
			type: Array,
			default: undefined,
		},
		icon: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			default: '',
		},
		badge: {
			type: String,
			default: '',
		},
		dropdownLocation: {
			type: String,
			default: 'bottom',
		},
		availableTools: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			tool: {},
		}
	},
	computed: {
		filteredItems() {
			if (!this.items) return []
			let active = this.items.filter(i => i.canShow === undefined || i.canShow())
			return addBorders(active)

			function addBorders(items) {
				const isDifferentToolGroup = (a, b) => a.groups && b.groups && a.groups.join() !== b.groups.join()
				items = items.map((item, i) => {
					if (i === 0) return item
					return {
						...item,
						hasBorder: isDifferentToolGroup(item, items[i - 1]),
					}
				})
				return items
			}
		},
		_icon() {
			return this.icon || this.tool.iconName || ''
		},
		_label() {
			return this.label || this.tool.label || ''
		},
		_title() {
			return this.title || this.tool.title || ''
		},
		_badge() {
			return this.badge || this.tool.binding || ''
		},
	},
	watch: {
		availableTools() {
			this.setButtonToFirstTool()
		},
	},
	created() {
		this.setButtonToFirstTool()
	},
	methods: {
		setButtonToFirstTool() {
			if (this.icon || this.label || !this.items || !this.items.length) return
			// Use the first bound tool if exists
			this.tool = this.items.find(item => item.alias && item.binding)
			// If none bound, use the first bindable tool
			if (!this.tool) {
				this.tool = this.items.find(item => item.alias)
			}
		},
		onSelect({ event, item }, hideDropdown) {
			if (item.disabled) return
			hideDropdown(true)
			if (item.alias) {
				this.$emit('activate-tool', { event, tool: item })
				this.tool = this.availableTools.find(tool => tool.alias === item.alias)
			} else if (item.command) {
				item.command()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.active-setting {
	margin-left: 4px;
}

.item-label {
	display: flex;
	align-items: center;
	padding: 4px 0;
	min-width: 96px;
	&.disabled {
		opacity: 0.4;
		cursor: not-allowed;
	}
	.badge {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 8px;
		padding: 4px;
		border-radius: 16px;
		margin: 0;
		min-width: 16px;
		height: 16px;
		margin-left: 4px;
	}
}
// TODO: make this a global tool
.no-break {
	white-space: nowrap;
}
</style>
