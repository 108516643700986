// Stripped down version of v1.1.0 https://github.com/vireshshah/custom-protocol-check accessed 8/26/2020
// Removed IE and old Edge checks to slim down package

const browser = {
	getUserAgent() {
		return window.navigator.userAgent
	},

	userAgentContains: function(browserName) {
		browserName = browserName.toLowerCase()
		return (
			this.getUserAgent()
				.toLowerCase()
				.indexOf(browserName) > -1
		)
	},

	isOSX() {
		return this.userAgentContains('Macintosh')
	},

	isFirefox() {
		return this.userAgentContains('firefox')
	},

	isEdge() {
		return this.userAgentContains('edge')
	},

	isChrome() {
		// IE11 returns undefined for window.chrome
		// and new Opera 30 outputs true for window.chrome
		// but needs to check if window.opr is not undefined
		// and new IE Edge outputs to true for window.chrome
		// and if not iOS Chrome check
		const isChromium = window.chrome
		const winNav = window.navigator
		const vendorName = winNav.vendor
		const isOpera = typeof window.opr !== 'undefined'
		const isIEedge = winNav.userAgent.indexOf('Edge') > -1
		const isIOSChrome = winNav.userAgent.match('CriOS')
		return (
			(isChromium !== null &&
				typeof isChromium !== 'undefined' &&
				vendorName === 'Google Inc.' &&
				isOpera === false &&
				isIEedge === false) ||
			isIOSChrome
		)
	},

	isOpera() {
		return this.userAgentContains(' OPR/')
	},
}

let DEFAULT_CUSTOM_PROTOCOL_FAIL_CALLBACK_TIMEOUT

const registerEvent = (target, eventType, cb) => {
	if (target.addEventListener) {
		target.addEventListener(eventType, cb)
		return {
			remove: function() {
				target.removeEventListener(eventType, cb)
			},
		}
	} else {
		target.attachEvent(eventType, cb)
		return {
			remove: function() {
				target.detachEvent(eventType, cb)
			},
		}
	}
}

const createHiddenIframe = (target, uri) => {
	let iframe = document.createElement('iframe')
	iframe.src = uri
	iframe.id = 'hiddenIframe'
	iframe.style.display = 'none'
	target.appendChild(iframe)

	return iframe
}

const openUriWithHiddenFrame = (uri, failCb, successCb) => {
	const timeout = setTimeout(function() {
		failCb()
		handler.remove()
	}, DEFAULT_CUSTOM_PROTOCOL_FAIL_CALLBACK_TIMEOUT)

	let iframe = document.querySelector('#hiddenIframe')
	if (!iframe) {
		iframe = createHiddenIframe(document.body, 'about:blank')
	}

	const onBlur = () => {
		clearTimeout(timeout)
		handler.remove()
		successCb()
	}
	const handler = registerEvent(window, 'blur', onBlur)

	iframe.contentWindow.location.href = uri
}

const openUriWithTimeoutHack = (uri, failCb, successCb) => {
	const timeout = setTimeout(function() {
		failCb()
		handler.remove()
	}, DEFAULT_CUSTOM_PROTOCOL_FAIL_CALLBACK_TIMEOUT)

	// handle page running in an iframe (blur must be registered with top level window)
	let target = window
	while (target.parent && target !== target.parent) {
		target = target.parent
	}

	const onBlur = () => {
		clearTimeout(timeout)
		handler.remove()
		successCb()
	}

	const handler = registerEvent(target, 'blur', onBlur)

	window.location = uri
}

export const protocolCheck = (uri, failCb, successCb, timeout = 2000, unsupportedCb) => {
	const failCallback = () => {
		failCb && failCb()
	}

	const successCallback = () => {
		successCb && successCb()
	}

	const unsupportedCallback = () => {
		unsupportedCb && unsupportedCb()
	}

	const openUri = () => {
		if (browser.isFirefox()) {
			openUriWithHiddenFrame(uri, failCallback, successCallback)
		} else if (browser.isChrome()) {
			openUriWithTimeoutHack(uri, failCallback, successCallback)
		} else if (browser.isOSX()) {
			openUriWithHiddenFrame(uri, failCallback, successCallback)
		} else {
			// not supported, implement please
			unsupportedCallback()
		}
	}

	if (timeout) {
		DEFAULT_CUSTOM_PROTOCOL_FAIL_CALLBACK_TIMEOUT = timeout
	}

	if (document.hasFocus()) {
		openUri()
	} else {
		let focusHandler = registerEvent(window, 'focus', () => {
			focusHandler.remove()
			openUri()
		})
	}
}

// Wrapper function that is new
export const protocolCheckPromise = (uri, timeout = 2000) => {
	return new Promise((resolve, reject) => {
		protocolCheck(
			uri,
			() => {
				reject(new Error('No registered protocol'))
			},
			resolve,
			timeout,
			() => {
				reject(new Error('Unsupported browser check'))
			}
		)
	})
}

export default protocolCheckPromise
