<template>
	<modal-wrapper @close="close">
		<div id="onetimemessage" class="modal-dialog">
			<dlg-header :title="title" @close="close">
				<svg-icon icon="star" />
			</dlg-header>
			<div class="modal-body scroll">
				{{ message }}
			</div>
			<div class="footer right">
				<button class="btn btn-primary" @click="close()">Close</button>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'

const OneTimeMessage = {
	name: 'OneTimeMessage',
	components: {
		DlgHeader,
		ModalWrapper,
	},
	props: {
		title: String,
		message: String,
	},
	methods: {
		close() {
			dlg.close(this)
		},
	},
}

export default OneTimeMessage

/**
 *  This dialog  is a custom one.
 *  It will display content according these properties
 * @param {String} title , for the header
 * @param {String} message , for the content
 */
export const openTimeMessage = (title, message) => {
	return dlg.open(
		OneTimeMessage,
		{
			title,
			message,
		},
		false
	)
}
</script>

<style lang="scss" scoped>
.onetimemessage {
	&:not(:last-child) {
		padding-bottom: 1rem;
		margin-bottom: 12px;
		border-bottom: 1px solid var(--primary-border);
	}
}
</style>

<style lang="scss">
@import '~@styles/_vars.scss';
#onetimemessage {
	@media (min-width: $mqMedium) {
		min-width: 475px;
	}
}
</style>
