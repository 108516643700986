<template>
	<modal-wrapper :close-on-escape="true" @close="close">
		<div class="dialog launch-dialog">
			<dlg-header title="Download Omni Desktop" @close="close" />
			<div class="modal-body">
				<template v-if="waiting">
					<div class="loading">
						<svg-icon icon="spinner-circle-med" spin size="3em" vcenter />
					</div>
				</template>
				<template v-else-if="!waiting && launched">
					<p>Launched Omni Desktop</p>
					<p>You can close this window now.</p>
				</template>
				<template v-else>
					<img :src="imgUrl" height="143px" />
					<p>
						Introducing <strong>Omni Desktop</strong>, the same Keystone Omni you know and love, now with Multi-Monitor
						support!
					</p>
					<div class="download">
						<template v-if="isWindows || isMac">
							<a :href="primaryLink" class="btn btn-primary">
								<svg-icon :icon="isMac ? 'os-apple' : 'os-windows'" style="margin-right: 8px" />
								Download Omni Desktop for {{ isMac ? 'Mac' : 'Windows' }}
							</a>
							<p style="color: var(--link)">
								<svg-icon :icon="isMac ? 'os-windows' : 'os-apple'" style="margin-right: 8px" />
								<a :href="secondaryLink">Omni Desktop for {{ isMac ? 'Windows' : 'Mac' }} </a>
							</p>
						</template>
						<template v-else>
							<a v-if="windowsLink" :href="windowsLink" class="btn btn-primary">
								<svg-icon icon="os-windows" style="margin-right: 8px" />Omni Desktop for Windows
							</a>
							<a v-if="macLink" :href="macLink" class="btn btn-primary">
								<svg-icon icon="os-apple" style="margin-right: 8px" />Omni Desktop for Mac
							</a>
						</template>
					</div>
				</template>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import { mapState } from 'vuex'
import { dlg } from '@utils/dlgUtils'
import DlgHeader from '@components/DlgHeader.vue'
import AstButton from '@components/Button.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import launchMagicUrl from '@/electron/launchMagicUrl'
import checkSafeToLogout from '@/utils/checkSafeToLogout'
import router from '@router'
import store from '@store'
import api from '@services/api'

const imgUrl = require('@/assets/compressed/multimonitor-sm.png')

const DownloadModal = {
	name: 'LaunchOrDownloadDesktop',
	components: {
		DlgHeader,
		ModalWrapper,
		AstButton,
	},
	props: {
		url: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			waiting: true,
			launched: false,
			imgUrl,
		}
	},
	computed: {
		...mapState({
			macLink: state => state.ui.desktopUpdateInfo.macInstallerUrl,
			windowsLink: state => state.ui.desktopUpdateInfo.winUnmanagedInstallerUrl,
		}),
		isWindows() {
			return navigator.userAgent.indexOf('Win') >= 0
		},
		isMac() {
			return navigator.userAgent.indexOf('Mac') >= 0
		},
		primaryLink() {
			return this.isMac ? this.macLink : this.windowsLink
		},
		secondaryLink() {
			return this.isMac ? this.windowsLink : this.macLink
		},
	},
	mounted() {
		// Launching mac version takes longer than 2 seconds to steal focus :/
		const timeout = this.isMac ? 3000 : 2000
		const { claims } = store.state.auth
		launchMagicUrl(this.url, timeout)
			.then(() => {
				// TODO: Log the user out... always? Only if Consultant?
				if (claims.isConsultantUser) {
					store.dispatch('logOut')
				}
				// Do we want to track Analytics events here?
				this.waiting = false
				this.launched = true
			})
			.catch(async e => {
				// Didn't launch Omni
				this.waiting = false
				this.launched = false
			})
	},
	methods: {
		close() {
			dlg.close(this)
		},
	},
}

export default DownloadModal

export function openLaunchOrDownloadDialog() {
	const { claims, token } = store.state.auth
	return new Promise(async (resolve, reject) => {
		// Check for unfinished work
		if (!(await checkSafeToLogout())) {
			return resolve(false)
		}
		// If a consultant, let them know they'll be logged out
		if (claims.isConsultantUser) {
			const msg = `
			This will log you out of your browser and resume your session in Omni Desktop, if it is installed.
			Please make sure you have saved any open work.
			`.trim()
			const proceed = await showConfirm(msg, {
				title: 'Launching Omni Desktop...',
				confirmText: 'Launch Omni Desktop',
			})
			if (!proceed) return resolve(false)
		}
		// get current route & add to url so desktop can seed the state
		const { href } = router.resolve({
			path: router.currentRoute.path,
			query: { ...router.currentRoute.query, token },
		})
		// Note: We're removing the hash part of the url here so Node's `url` package will parse the query params correctly.
		// otherwise, the query param is parsed as part of the hash and useless.
		// Other Note: we aren't actually using the url yet, but will in the future.
		const url = `keystone://launch/${href.replace('#/', '')}`
		const result = await dlg.open(DownloadModal, { url }, false)
		return resolve(result)
	})
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.launch-dialog {
	border: none;
	display: flex;
	position: relative;
	flex-direction: column;
	padding: 0;
	max-width: 100vw;
	max-height: 100vh;
	@media (min-width: $mqSmall) {
		width: 800px;
		max-width: 90vw;
		max-height: 75vh;
	}
	.modal-body {
		flex-grow: 1;
		background: var(--primary-bg);
		border-top: 1px solid var(--primary-border);
		overflow-y: auto;
		padding: 16px;
		text-align: center;
	}
	img {
		display: block;
		max-width: 350px;
		align-self: center;
		margin: 0 auto;
	}
	.download {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 0.5rem;
	}
	a.btn {
		font-size: 16px;
	}

	a.btn + a.btn {
		margin-top: 1rem;
	}
}
p {
	margin: 1em 0;
}
ul {
	margin: 0 auto 1em;
	display: inline-block;
	text-align: left;
}
.loading {
	min-height: 284px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
</style>
